import cx from 'classnames';
import styles from '../styles/Products.module.css';

const Products = ({products, selectProduct, selectedProduct}) => (
  <div className={styles.categories}>
    {products.map(category => category.products && (
      <div key={category.id} className={styles.category}>
        <div className={styles.categoryHeader}>
          <img src={category.icon_image} alt="" className={styles.categoryIcon} />
          <span className={styles.categoryName}>
            {category.name}
          </span>
        </div>
        <div className={styles.products}>
          {category.products.map(product => (
            <div key={product.id} className={styles.product} onClick={() => (product)}>
              {selectedProduct && product.id === selectedProduct.id && <div className={styles.bullet} />}
              <span
                className={cx(styles.productName, selectedProduct && product.id === selectedProduct.id && styles.active)}>
                {product.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);

export default Products;
